import { useCallback } from "react"

import PDFViewer from "../components/PDFViewer"

export default function PDFAnnotator({ documentURL = "/document.pdf" }) {
  const handleAnnotations = useCallback((capturedAnnotations) => {
    console.log({ capturedAnnotations })
    localStorage.setItem("PDFAnnotations", JSON.stringify(capturedAnnotations))
  }, [])

  return (
    <div className="PDF-viewer">
      <PDFViewer
        documentURL={documentURL}
        onGetAnnotations={handleAnnotations}
      />
    </div>
  )
}
