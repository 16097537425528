import { useCallback, useEffect, useRef, Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { InformationCircleIcon } from '@heroicons/react/solid'

import CONFIG from '../config/config'

function FormOverlay({ open, setOpen = () => {}, onFinished = () => {} }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <form className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">Send update requests</Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">
                          Please check if you've included all the changes you'd like us to include.
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="px-4 divide-y divide-gray-200 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <div>
                            <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                              Any comments?
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="description"
                                name="description"
                                rows={4}
                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                                defaultValue={''}
                              />
                            </div>
                          </div>

                          <fieldset>
                            <legend className="text-sm font-medium text-gray-900">Urgency level</legend>
                            <div className="mt-2 space-y-5">
                              <div className="relative flex items-start">
                                <div className="absolute flex items-center h-5">
                                  <input
                                    id="privacy-public"
                                    name="privacy"
                                    aria-describedby="privacy-public-description"
                                    type="radio"
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    defaultChecked
                                  />
                                </div>
                                <div className="pl-7 text-sm">
                                  <label htmlFor="privacy-public" className="font-medium text-gray-900">
                                    A few days is fine
                                  </label>
                                  <p id="privacy-public-description" className="text-gray-500">
                                    We won't take advantage, we promise to get back to you ASAP!
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="relative flex items-start">
                                  <div className="absolute flex items-center h-5">
                                    <input
                                      id="privacy-private-to-project"
                                      name="privacy"
                                      aria-describedby="privacy-private-to-project-description"
                                      type="radio"
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    />
                                  </div>
                                  <div className="pl-7 text-sm">
                                    <label htmlFor="privacy-private-to-project" className="font-medium text-gray-900">
                                      Pretty urgent
                                    </label>
                                    <p id="privacy-private-to-project-description" className="text-gray-500">
                                      If you need to present the updated project for a quote
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="relative flex items-start">
                                  <div className="absolute flex items-center h-5">
                                    <input
                                      id="privacy-private"
                                      name="privacy"
                                      aria-describedby="privacy-private-to-project-description"
                                      type="radio"
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    />
                                  </div>
                                  <div className="pl-7 text-sm">
                                    <label htmlFor="privacy-private" className="font-medium text-gray-900">
                                      I need it yesterday!
                                    </label>
                                    <p id="privacy-private-description" className="text-gray-500">
                                      We get it, it won't be yesterday but we'll do our best to accommodate your
                                      timeline (add info in the comment).
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="pt-4 pb-6">
                          <div className="mt-4 flex text-sm">
                            <div href="#" className="group inline-flex items-center text-gray-500 hover:text-gray-900">
                              <InformationCircleIcon
                                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <span className="ml-2">
                                Please keep in mind that urgent requests are quoted with 10-20% premium
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={onFinished}
                      className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default function PdfViewerInteral({
  documentURL,
  onGetAnnotations = annotations => {
    console.log('onGetAnnotations called (defult)')
  },
}) {
  const containerRef = useRef(null)
  const instanceRef = useRef(null)
  const [open, setOpen] = useState(false)
  const getAnnotations = useCallback(async () => {
    // const annotations = await instanceRef.current.getAnnotations(0)
    const instantAnnotations = await instanceRef.current.exportInstantJSON()
    onGetAnnotations(instantAnnotations)
  }, [onGetAnnotations])

  useEffect(() => {
    const container = containerRef.current
    let instance, PSPDFKit
    let instantJSON = JSON.parse(localStorage.getItem('PDFAnnotations'))
    ;(async function () {
      PSPDFKit = await import('pspdfkit')
      console.log(`${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`)
      instance = await PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        licenseKey: CONFIG.pdfLicenseKey || null,

        container,
        instantJSON,
        // The document to open.
        document: documentURL,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        // disableWebAssemblyStreaming: true,
      })
      instanceRef.current = instance
    })()

    return () => {
      PSPDFKit && PSPDFKit.unload(container)
      instanceRef.current = null
    }
  }, [documentURL])

  const handleFinished = useCallback(
    formData => {
      setOpen(false)
      getAnnotations()
    },
    [getAnnotations]
  )

  return (
    <>
      <div className="max-w-2xl mx-auto items-start  lg:max-w-7xl  flex pb-16">
        <div>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Ready to send us your ideas?
          </h2>
          <p className="mt-4 text-gray-500">Just annotate the project and hit request changes when you're done</p>
          <button
            // onClick={getAnnotations}
            onClick={() => {
              setOpen(true)
            }}
            className="w-44 mt-8 bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
          >
            All done
          </button>
        </div>
      </div>
      <div className="bg-white">
        <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />
      </div>
      <FormOverlay open={open} setOpen={setOpen} onFinished={handleFinished} />
    </>
  )
}
