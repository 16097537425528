import React from 'react'
import classnames from 'classnames'
import { Rings } from 'react-loader-spinner'

const AButton = ({
  icon,
  loadingIcon = <Rings type="Rings" color="white" height={16} width={16} />,
  loadingText = 'Loading...',
  overwriteStyles = false,
  isLoading,
  className = '',
  children,
  type = 'button',
  fullBorderRadius = false,
  primary = true,
  secondary = false,
  disabled = false,
  onClick = () => {},
}) => {
  const buttonClassNames = overwriteStyles
    ? className
    : `cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium ${
        primary && !secondary
          ? 'text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700'
          : ''
      } ${
        secondary
          ? 'border border-gray-300 text-sm leading-5 font-medium rounded-md text-hub-blue bg-transparent hover:bg-white focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 active:text-white'
          : ''
      } ${fullBorderRadius ? 'rounded-full' : 'rounded-md'} ${className} ${disabled && 'opacity-50'}`

  return (
    <button
      onClick={!isLoading ? onClick : undefined}
      type={type}
      className={classnames(buttonClassNames, { 'opacity-50 pointer-events-none': isLoading })}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <>
          {Boolean(loadingIcon) && <div className="mr-2">{loadingIcon}</div>}
          {Boolean(loadingText) && <span>{loadingText}</span>}
        </>
      ) : (
        <>
          {Boolean(icon) && <div className="mr-2">{icon}</div>}
          <span>{children}</span>
        </>
      )}
    </button>
  )
}

export default AButton
