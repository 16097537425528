import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'

import OLoader from '../components/organisms/OLoader'
import { auth } from '../services/firebase'

export default function PSignInWithEmailLink() {
  const location = useLocation()

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      var email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        email = window.prompt('Please provide your email for confirmation')
      }

      signInWithEmailLink(auth, email, window.location.href)
        .then(result => {
          window.localStorage.removeItem('emailForSignIn')
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [location?.pathname])

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center">
      <OLoader />
    </div>
  )
}
