import { useMemo } from 'react'
import { Outlet, useMatch } from 'react-router'
import { Link } from 'react-router-dom'

const relatedProducts = [
  {
    id: 2,
    name: 'Modern Alpine Plus',
    category: '2 Bedroom Modern Loft Cabin',
    price: '$299',
    imageSrc: '//cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_08_1800x1800.jpg?v=1633454561',
    imageAlt: 'Modern Alpine Plus',
  },
  // More products...
]
const footerNavigation = {
  learn: [
    { name: 'How it works', href: 'https://denoutdoors.com/pages/how-it-works' },
    { name: 'Plans', href: 'https://denoutdoors.com/collections/small-house-and-aframe-plans' },
    { name: 'Packages', href: 'https://denoutdoors.com/pages/plan-packages' },
    { name: 'Featured projects', href: 'https://denoutdoors.com/pages/featured-projects' },
  ],
  company: [
    { name: 'Our Manifesto', href: 'https://denoutdoors.com/pages/about-den' },
    { name: 'Contact us', href: 'https://denoutdoors.com/pages/contact-us' },
    { name: 'Press', href: '#' },
    { name: 'Careers', href: '#' },
    { name: 'Privacy', href: '#' },
  ],
  customerService: [
    { name: 'Chat', href: '#' },
    { name: 'Contact', href: '#' },
    { name: 'Secure Payments', href: '#' },
    { name: 'FAQ', href: '#' },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
const allTabs = [
  { name: 'Overview', href: 'overview' },
  { name: 'Share', href: 'share' },
  { name: 'Customize', href: 'customize' },
  { name: 'Engineer', href: 'engineer' },
  { name: 'Finance', href: 'finance' },
  { name: 'Build', href: 'build' },
  { name: 'Live', href: 'live' },
  { name: 'Rent', href: 'rent' },
]

function MainTabs() {
  const match = useMatch('/dashboard/:id/:tab/*')

  const tabs = useMemo(() => {
    let currentTabHref = match ? match.params.tab : 'overview'

    return allTabs.map(tab => {
      let isCurrent = tab.href === currentTabHref
      return { ...tab, ...{ current: isCurrent } }
    })
  }, [match])

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={tabs.find(tab => tab.current).name}
        >
          {tabs.map(tab => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map(tab => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default function ProjectDetails() {
  return (
    <div className="bg-white">
      <header className="relative bg-white">
        {/* <p className="bg-indigo-600 h-10 flex items-center justify-center text-sm font-medium text-white px-4 sm:px-6 lg:px-8">
          Save 20% when you buy two or more plans
        </p> */}

        <nav aria-label="Top" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="border-b border-gray-200">
            <div className="h-16 flex items-center">
              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <Link to="/">
                  <span className="sr-only">DEN</span>
                  <img
                    className="h-8 w-auto"
                    src="//cdn.shopify.com/s/files/1/0280/5429/0496/files/Den-Logo-RGB-Black_80x.png?v=1590025709"
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
          <MainTabs />
        </nav>
      </header>

      <main className="mx-auto pt-14 pb-6 px-4 sm:pt-16 sm:pb-8 sm:px-6 lg:max-w-7xl lg:px-8">
        <Outlet />

        {/* Related products */}
        <div className="max-w-2xl mx-auto mt-16 sm:mt-2 lg:max-w-none">
          <div className="flex items-center justify-between space-x-4">
            <h2 className="text-lg font-medium text-gray-900">You may also like</h2>
            <a href="#" className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-500">
              View all<span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
            {relatedProducts.map(product => (
              <div key={product.id} className="relative group">
                <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden bg-gray-100">
                  <img src={product.imageSrc} alt={product.imageAlt} className="object-center object-cover" />
                  <div className="flex items-end opacity-0 p-4 group-hover:opacity-100" aria-hidden="true">
                    <div className="w-full bg-white bg-opacity-75 backdrop-filter backdrop-blur py-2 px-4 rounded-md text-sm font-medium text-gray-900 text-center">
                      View Plan
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900 space-x-8">
                  <h3>
                    <Link to={`/dashboard/${product.id}`}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </Link>
                  </h3>
                  <p>{product.price}</p>
                </div>
                <p className="mt-1 text-sm text-gray-500">{product.category}</p>
              </div>
            ))}
          </div>
        </div>
      </main>

      <footer aria-labelledby="footer-heading" className="bg-gray-50">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-20 grid grid-cols-1 md:grid-cols-12 md:grid-flow-col md:gap-x-8 md:gap-y-16 md:auto-rows-min">
            {/* Image section */}
            <div className="col-span-1 md:col-span-2 lg:row-start-1 lg:col-start-1">
              <img
                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"
                alt=""
                className="h-8 w-auto"
              />
            </div>

            {/* Sitemap sections */}
            <div className="mt-10 col-span-6 grid grid-cols-2 gap-8 sm:grid-cols-3 md:mt-0 md:row-start-1 md:col-start-3 md:col-span-8 lg:col-start-2 lg:col-span-6">
              <div className="grid grid-cols-1 gap-y-12 sm:col-span-2 sm:grid-cols-2 sm:gap-x-8">
                <div>
                  <h3 className="text-sm font-medium text-gray-900">Learn</h3>
                  <ul className="mt-6 space-y-6">
                    {footerNavigation.learn.map(item => (
                      <li key={item.name} className="text-sm">
                        <a href={item.href} className="text-gray-500 hover:text-gray-600">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-900">About Us</h3>
                  <ul className="mt-6 space-y-6">
                    {footerNavigation.company.map(item => (
                      <li key={item.name} className="text-sm">
                        <a href={item.href} className="text-gray-500 hover:text-gray-600">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-900">Customer Service</h3>
                <ul className="mt-6 space-y-6">
                  {footerNavigation.customerService.map(item => (
                    <li key={item.name} className="text-sm">
                      <a href={item.href} className="text-gray-500 hover:text-gray-600">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Newsletter section */}
            <div className="mt-12 md:mt-0 md:row-start-2 md:col-start-3 md:col-span-8 lg:row-start-1 lg:col-start-9 lg:col-span-4">
              <h3 className="text-sm font-medium text-gray-900">Sign up for our newsletter</h3>
              <p className="mt-1 text-sm text-gray-500">Be the first to know when we release new products.</p>
              <form className="mt-3 sm:flex lg:block xl:flex">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  type="text"
                  autoComplete="email"
                  required
                  className="block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 sm:flex-1 sm:min-w-0 sm:max-w-xs lg:max-w-none"
                />
                <div className="mt-4 sm:mt-0 sm:ml-4 sm:flex-shrink-0 lg:mt-4 lg:ml-0 xl:mt-0 xl:ml-4">
                  <button
                    type="submit"
                    className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Sign up
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="border-t border-gray-200 py-10 text-center">
            <p className="text-sm text-gray-500">&copy; 2022 DEN.</p>
          </div>
        </div>
      </footer>
    </div>
  )
}
