import { useMemo } from 'react'
import { CheckIcon } from '@heroicons/react/solid'
import { Link, useParams } from 'react-router-dom'
import useStore from '../store/useStore'
import _ from 'lodash'

const steps = [
  {
    name: 'Request customization',
    description: "You'll be ble to annotate your plan within your dashboard",
    href: '#',
    status: 'current',
  },
  {
    name: 'Quote',
    description: "We'll come back with a quote for you're changes",
    href: '#',
    status: 'upcoming',
  },
  {
    name: 'Pay',
    description: "If you accept the quote, you'll be able to pay it",
    href: '#',
    status: 'upcoming',
  },
  {
    name: 'Delivery',
    description: "We'll be working hard to deliver your updated plan. It usually takes a few days.",
    href: '#',
    status: 'upcoming',
  },
  {
    name: 'Review',
    description: "You'll get notifified once it's done. Updated plan will be available here.",
    href: '#',
    status: 'upcoming',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function CustomizationExplanier({ images }) {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
        <div>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Customization process explained
          </h2>
          <p className="mt-4 text-gray-500">
            Let the natural world pass through your Den with massive picture windows and sliding glass doors perfectly
            aligned for a feeling of being one with nature inside of your own safe space. Walkout wrap around decking,
            two full bedrooms, and lofted ceilings add to the staples of a home that this full-featured farmhouse
            provides.
          </p>

          <nav aria-label="Progress" className="mt-8">
            <ol className="overflow-hidden">
              {steps.map((step, stepIdx) => (
                <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
                  {step.status === 'complete' ? (
                    <>
                      {stepIdx !== steps.length - 1 ? (
                        <div
                          className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600"
                          aria-hidden="true"
                        />
                      ) : null}
                      <a href={step.href} className="relative flex items-start group">
                        <span className="h-9 flex items-center">
                          <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                            <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                          </span>
                        </span>
                        <span className="ml-4 min-w-0 flex flex-col">
                          <span className="text-xs font-semibold tracking-wide uppercase">{step.name}</span>
                          <span className="text-sm text-gray-500">{step.description}</span>
                        </span>
                      </a>
                    </>
                  ) : step.status === 'current' ? (
                    <>
                      {stepIdx !== steps.length - 1 ? (
                        <div
                          className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                          aria-hidden="true"
                        />
                      ) : null}
                      <a href={step.href} className="relative flex items-start group" aria-current="step">
                        <span className="h-9 flex items-center" aria-hidden="true">
                          <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                            <span className="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
                          </span>
                        </span>
                        <span className="ml-4 min-w-0 flex flex-col">
                          <span className="text-xs font-semibold tracking-wide uppercase text-indigo-600">
                            {step.name}
                          </span>
                          <span className="text-sm text-gray-500">{step.description}</span>
                        </span>
                      </a>
                    </>
                  ) : (
                    <>
                      {stepIdx !== steps.length - 1 ? (
                        <div
                          className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                          aria-hidden="true"
                        />
                      ) : null}
                      <a href={step.href} className="relative flex items-start group">
                        <span className="h-9 flex items-center" aria-hidden="true">
                          <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                            <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                          </span>
                        </span>
                        <span className="ml-4 min-w-0 flex flex-col">
                          <span className="text-xs font-semibold tracking-wide uppercase text-gray-500">
                            {step.name}
                          </span>
                          <span className="text-sm text-gray-500">{step.description}</span>
                        </span>
                      </a>
                    </>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          {images.map((image, index) => (
            <img key={index} src={image} alt="" className="bg-gray-100 rounded-lg" />
          ))}
        </div>
      </div>
    </div>
  )
}

export default function ProjetCustomize() {
  const { projectId } = useParams()
  const plans = useStore(state => state.plans)
  const plansContents = useStore(state => state.plansContents)
  const getPlanById = useStore(state => state.getPlanById)
  const getPlanContentsById = useStore(state => state.getPlanContentsById)

  const plan = useMemo(() => {
    if (projectId && plans.length > 0 && plansContents.length > 0) {
      const planObj = getPlanById(projectId)
      return getPlanContentsById(planObj.planId)
    }
    return null
  }, [projectId, plans.length, plansContents.length, getPlanById, getPlanContentsById])

  if (!plan) {
    return null
  }

  return (
    <>
      {/* Product */}
      <div className="lg:grid lg:grid-rows-1 lg:grid-cols-7 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
        {/* Product image */}
        <div className="lg:row-end-1 lg:col-span-4">
          <div className="aspect-w-4 aspect-h-3 rounded-lg bg-gray-100 overflow-hidden">
            <img src={plan.customization.headerImage} alt={plan.imageAlt} className="object-center object-cover" />
          </div>
        </div>

        {/* Product details */}
        <div className="max-w-2xl mx-auto mt-14 sm:mt-16 lg:max-w-none lg:mt-0 lg:row-end-2 lg:row-span-2 lg:col-span-3">
          <div className="flex flex-col-reverse">
            <div className="mt-4">
              <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                {plan.customization?.title}
              </h1>

              <h2 id="information-heading" className="sr-only">
                Plan information
              </h2>
              <p className="text-sm text-gray-500 mt-2">
                Version {plan.version.name} (Updated <time dateTime={plan.version.datetime}>{plan.version.date}</time>)
              </p>
            </div>
          </div>

          <p className="text-gray-500 mt-6">{plan.customization.description}</p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
            <Link
              to={`/dashboard/${projectId}/customize/annotate`}
              className="w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
            >
              Request Changes
            </Link>
            {/* <button
              type="button"
              className="w-full bg-indigo-50 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
            >
              Get Receipt
            </button> */}
          </div>
        </div>
      </div>
      <CustomizationExplanier
        title={plan.customization.title}
        description={plan.customization.description}
        images={_.shuffle(plan.images).slice(0, 4)}
      />
    </>
  )
}
