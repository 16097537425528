import { useState, useEffect, useMemo, Fragment, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { useParams } from 'react-router'
import { useForm } from 'react-hook-form'

import AButton from '../components/atoms/AButton'
import { getPlanAccess, inviteToPlan } from '../services/firebase'

import useStore from '../store/useStore'

function InviteModal({ showModal = false, onModalClose = () => {}, onInviteSave = () => {}, isSaving = false }) {
  const {
    register,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm()

  const onSubmit = useCallback(
    data => {
      if (!isSaving) {
        onInviteSave(data, reset)
      }
    },
    [isSaving, reset, onInviteSave]
  )

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={onModalClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
                >
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">Share your plan</Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={onModalClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-indigo-300">Invite people you'd like to have access to this plan.</p>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="px-4 divide-y divide-gray-200 sm:px-6">
                        <div className="space-y-6 pt-8 pb-5">
                          <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                            <label
                              htmlFor="name"
                              className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                            >
                              Name
                            </label>
                            <input
                              type="text"
                              {...register('inviteeName', { required: true })}
                              className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                              placeholder="At least first name, please:)"
                            />
                          </div>
                          <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                            <label
                              htmlFor="name"
                              className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              {...register('inviteeEmail', { required: true })}
                              className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                              placeholder="someone@example.com"
                            />
                          </div>
                          <fieldset>
                            <legend className="text-sm font-medium text-gray-900">
                              Wht should they be able to do with this plan?
                            </legend>
                            <div className="mt-2 space-y-5">
                              <div className="relative flex items-start">
                                <div className="absolute flex items-center h-5">
                                  <input
                                    id="role-manager"
                                    name="role"
                                    aria-describedby="role-manager-description"
                                    type="radio"
                                    {...register('inviteeRole')}
                                    value="manager"
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    defaultChecked
                                  />
                                </div>
                                <div className="pl-7 text-sm">
                                  <label htmlFor="role-manager" className="font-medium text-gray-900">
                                    Manage project
                                  </label>
                                  <p id="role-manager-description" className="text-gray-500">
                                    They will be able to have full manager access to this plan. That includes requesting
                                    customizations
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div className="relative flex items-start">
                                  <div className="absolute flex items-center h-5">
                                    <input
                                      id="role-reader"
                                      name="role"
                                      {...register('inviteeRole')}
                                      aria-describedby="role-reader-description"
                                      type="radio"
                                      value="reader"
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    />
                                  </div>
                                  <div className="pl-7 text-sm">
                                    <label htmlFor="role-reader-description" className="font-medium text-gray-900">
                                      View only
                                    </label>
                                    <p id="role-reader-description" className="text-gray-500">
                                      They will only be able to see the plan. Won't be able to make any changes.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <div>
                            <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                              Maybe add a personal note explaining why you're inviting them? (optional)
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="personal-note"
                                name="personal-note"
                                {...register('personalNote')}
                                rows={4}
                                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                                defaultValue={''}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="pt-4 pb-6">
                          <div className="mt-4 flex text-sm">
                            <div href="#" className="group inline-flex items-center text-gray-500 hover:text-gray-900">
                              <InformationCircleIcon
                                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <span className="ml-2">We'll send an email with the invitation to this plan</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={onModalClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSaving}
                      className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {isSaving ? 'Saving....' : 'Send invitation'}
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function Users({
  title = 'Features',
  description = '',
  users = [],
  images = [],
  currentUser = {},
  onShowInviteModal = () => {},
}) {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-24 px-4 grid items-start grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
        <div>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">{title}</h2>
          <p className="mt-4 text-gray-500">{description}</p>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            <>
              {users?.map(user => (
                <div key={user.email} className="border-t border-gray-200 pt-4">
                  <dd className="mt-2 mb-4 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-100 text-gray-800">
                    {user.role}
                  </dd>
                  <dt className="font-medium text-gray-900">
                    {user.name} {user.id === currentUser.id && '(You)'}
                  </dt>
                  <dd className="mt-2 text-sm text-gray-500">{user.email}</dd>
                </div>
              ))}
              <div key={'add user'} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900 flex items-center justify-center">
                  <AButton onClick={onShowInviteModal}>Invite someone</AButton>
                </dt>
              </div>
            </>
          </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          {images.map((image, index) => (
            <img key={index} src={image} alt="" className="bg-gray-100 rounded-lg" />
          ))}
        </div>
      </div>
    </div>
  )
}

export default function ProjectOverview() {
  const { projectId } = useParams()
  const plans = useStore(state => state.plans)
  const currentUser = useStore(state => state.user)
  const plansContents = useStore(state => state.plansContents)
  const getPlanById = useStore(state => state.getPlanById)
  const getPlanContentsById = useStore(state => state.getPlanContentsById)
  const [planAccess, setPlanAccess] = useState(null)
  const [isSavingInvite, setIsSavingInvite] = useState(false)

  const [showInviteModal, setShowInviteModal] = useState(false)

  const plan = useMemo(() => {
    if (projectId && plans.length > 0 && plansContents.length > 0) {
      const planObj = getPlanById(projectId)
      return getPlanContentsById(planObj.planId)
    }
    return null
  }, [projectId, plans.length, plansContents.length, getPlanById, getPlanContentsById])

  useEffect(() => {
    async function loadPlanAccess(planId) {
      if (planId) {
        const response = await getPlanAccess({ planId: planId })

        setPlanAccess(response.data.users)
      }
    }
    loadPlanAccess(projectId)
  }, [projectId])

  const handleSendInvite = useCallback(
    async ({ inviteeEmail, inviteeName, inviteeRole, personalNote }, reset) => {
      setIsSavingInvite(true)
      try {
        await inviteToPlan({ planId: projectId, inviteeEmail, inviteeName, inviteeRole, personalNote })
        const response = await getPlanAccess({ planId: projectId })
        setPlanAccess(response.data.users)
        setShowInviteModal(false)
        reset()
      } catch (err) {
        console.log({ errOnInviteSave: err })
      } finally {
        setIsSavingInvite(false)
      }
    },
    [projectId]
  )

  if (!plan) {
    return null
  }

  return (
    <>
      <Users
        title={!planAccess ? 'Loading invited users...' : 'Invited users'}
        description={`Manage access to your ${plan.name} plan`}
        users={planAccess}
        currentUser={currentUser}
        images={plan.images.slice(0, 6) || []}
        onShowInviteModal={() => {
          setShowInviteModal(true)
        }}
      />
      <InviteModal
        isSaving={isSavingInvite}
        showModal={showInviteModal}
        onModalClose={() => {
          setShowInviteModal(false)
        }}
        onInviteSave={handleSendInvite}
      />
    </>
  )
}
