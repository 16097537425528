import { useMemo } from "react"
import { useParams, useMatch } from "react-router-dom"
import _ from "lodash"
import useStore from "../store/useStore"

function Features({ images, features }) {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
        <div>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            What we'll cover
          </h2>
          <p className="mt-4 text-gray-500">
            Before you can start building you'll need to prepare. This will be
            your guide
          </p>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.name}</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt=""
              className="bg-gray-100 rounded-lg"
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default function ProjectOverview({
  mainImageURL = "//cdn.shopify.com/s/files/1/0280/5429/0496/files/image1_1080x.jpg?v=1605802339",
  description = "We're working on building a step by step tutorial that will cover everything you need to do before you're ready to start building ",
}) {
  const { projectId } = useParams()
  const plans = useStore((state) => state.plans)
  const getPlanById = useStore((state) => state.getPlanById)

  const plan = useMemo(() => {
    if (projectId && plans.length > 0) {
      return getPlanById(projectId)
    }
    return null
  }, [plans, projectId, getPlanById])

  const upcoming = useStore((state) => state.upcoming)
  const match = useMatch("/dashboard/:id/:tab/*")

  const upcomingData = useMemo(() => {
    let currentTabHref = match ? match.params.tab : "overview"

    return upcoming[currentTabHref]
  }, [match, upcoming])

  if (!plan) {
    return null
  }

  return (
    <>
      {/* Product */}
      <div className="lg:grid lg:grid-rows-1 lg:grid-cols-7 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
        {/* Product image */}
        <div className="lg:row-end-1 lg:col-span-4">
          <div className="aspect-w-4 aspect-h-3 rounded-lg bg-gray-100 overflow-hidden">
            <img
              src={upcomingData.imageSrc}
              alt=""
              className="object-center object-cover"
            />
          </div>
        </div>

        {/* Product details */}
        <div className="max-w-2xl mx-auto mt-14 sm:mt-16 lg:max-w-none lg:mt-0 lg:row-end-2 lg:row-span-2 lg:col-span-3">
          <div className="flex flex-col-reverse">
            <div className="mt-4">
              <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                {upcomingData.title}
              </h1>
            </div>
          </div>

          <p className="text-gray-500 mt-6">{upcomingData.description}</p>
        </div>
      </div>
      {upcomingData.features && (
        <Features
          images={_.shuffle(plan.images).slice(0, 4) || []}
          features={upcomingData.features}
        />
      )}
    </>
  )
}
