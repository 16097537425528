import { useEffect } from 'react'
import { signOut } from 'firebase/auth'
import { Route, Navigate, Routes, useLocation } from 'react-router-dom'
import { auth } from './services/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

import './index.css'

import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import ProjectDetails from './pages/ProjectDetails'
import ProjectOverview from './pages/ProjectOverview'
import ProjetCustomize from './pages/ProjectCustomize'
import ProjectUpcoming from './pages/ProjectUpcoming'
import ProjectAnnotations from './pages/ProjectAnnotations'
import ProjectShare from './pages/ProjectShare'
import SignInWithEmailLink from './pages/SignInWithEmailLink'
import useStore from './store/useStore'

function RequireAuth({ children }) {
  const location = useLocation()
  const [user, loading] = useAuthState(auth)

  if (loading) {
    return null
  }

  if (!user?.uid) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return children
}

function App() {
  const loadUserData = useStore(state => state.loadUserData)
  const unsubscribeListeners = useStore(state => state.unsubscribeListeners)
  const plans = useStore(state => state.plans)
  const [user, loading] = useAuthState(auth)

  useEffect(() => {
    if (user?.uid) {
      loadUserData(user.uid, user)
      // signOut(auth)
    }
    return unsubscribeListeners
  }, [user, unsubscribeListeners, loadUserData])

  useEffect(() => {
    console.log({ plans })
  }, [plans])

  if (loading) {
    return null
  }

  if (!user?.uid) {
    return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route exact path="/sign-in-with-email-link" element={<SignInWithEmailLink />} />
        <Route exact={true} path="/" element={<Navigate to="/login" replace />} />
      </Routes>
    )
  }

  return (
    <>
      <Routes>
        <Route path="/sign-in-with-email-link" element={<Navigate replace to="/" />} />
        <Route exact={true} path="/" element={<Navigate to="/dashboard" replace />} />
        <Route
          path="dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />

        <Route path="dashboard/:projectId" element={<ProjectDetails />}>
          <Route index element={<Navigate to="overview" replace />} />
          <Route path="share" element={<ProjectShare />} />
          <Route path="overview" element={<ProjectOverview />} />
          <Route path="customize" element={<ProjetCustomize />} />
          <Route path="customize/annotate" element={<ProjectAnnotations document={'document.pdf'} />} />

          <Route path="*" element={<ProjectUpcoming />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
