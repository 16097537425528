import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import useStore from '../store/useStore'
import OLoader from '../components/organisms/OLoader'

const footerNavigation = {
  company: [
    { name: 'Manifesto', href: '#' },
    { name: 'Contact Us', href: '#' },
    { name: 'Licensing', href: '#' },
    { name: 'Terms & Conditions', href: '#' },
    { name: 'Privacy Policy', href: '#' },
  ],
  customerService: [
    { name: 'Contact', href: '#' },
    { name: 'Shipping', href: '#' },
    { name: 'Returns', href: '#' },
    { name: 'Warranty', href: '#' },
    { name: 'Secure Payments', href: '#' },
    { name: 'FAQ', href: '#' },
    { name: 'Find a store', href: '#' },
  ],
}

export default function Dashboard() {
  const plans = useStore(state => state.plans)
  const plansContents = useStore(state => state.plansContents)
  const getPlanContentsById = useStore(state => state.getPlanContentsById)
  const getUserOwnedPlans = useStore(state => state.getUserOwnedPlans)
  const getSharedWithUserPlans = useStore(state => state.getSharedWithUserPlans)

  const [ownedPlans, sharedWithPlans] = useMemo(() => {
    let owned = []
    let sharedWith = []
    if (plans?.length) {
      sharedWith = getSharedWithUserPlans()
      owned = getUserOwnedPlans()
    }

    return [owned, sharedWith]
  }, [getSharedWithUserPlans, getUserOwnedPlans, plans])

  console.log({ ownedPlans, sharedWithPlans })
  if (!plansContents.length) {
    return <OLoader />
  }

  return (
    <div className="bg-white">
      <main
        className="max-w-2xl mx-auto py-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8"
        aria-labelledby="order-history-heading"
      >
        <div className="max-w-xl">
          <h2 id="order-history-heading" className="text-3xl font-extrabold tracking-tight text-gray-900">
            Your plans
          </h2>
          <p className="mt-2 text-sm text-gray-500">
            {ownedPlans?.length ? 'Jump into your projects to start building!' : "You don't own any plans yet"}
          </p>
          {!Boolean(ownedPlans?.length) && (
            <a href="https://denoutdoors.com" className="pointer underline">
              Browse our plans
            </a>
          )}
        </div>

        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
          {ownedPlans?.map(plan => (
            <div key={plan.id} className="group relative">
              <Link to={`/dashboard/${plan.id}`}>
                <div className="aspect-w-1 aspect-h-1 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75">
                  <img
                    src={getPlanContentsById(plan.planId).imageSrc}
                    alt={getPlanContentsById(plan.planId).imageAlt}
                    className="object-center object-cover"
                  />
                </div>
                <h3 className="mt-4 text-sm text-gray-500">
                  <span className="absolute inset-0" />
                  {getPlanContentsById(plan.planId).package}
                </h3>
                <p className="mt-1 text-lg font-medium">
                  <span className="text-gray-900">{getPlanContentsById(plan.planId).name}</span>
                </p>
              </Link>
            </div>
          ))}
        </div>
        {Boolean(sharedWithPlans?.length) && (
          <>
            <div className="max-w-xl mt-16">
              <h2 id="order-history-heading" className="text-3xl font-extrabold tracking-tight text-gray-900">
                Plans shared with you
              </h2>
              <p className="mt-2 text-sm text-gray-500">Jump into your projects to start building!</p>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
              {sharedWithPlans?.map(plan => (
                <div key={plan.id} className="group relative">
                  <Link to={`/dashboard/${plan.id}`}>
                    <div className="aspect-w-1 aspect-h-1 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75">
                      <img
                        src={getPlanContentsById(plan.planId).imageSrc}
                        alt={getPlanContentsById(plan.planId).imageAlt}
                        className="object-center object-cover"
                      />
                    </div>
                    <h3 className="mt-4 text-sm text-gray-500">
                      <span className="absolute inset-0" />
                      Shared from {plan?.ownerInfo?.name} ({plan?.ownerInfo?.email})
                    </h3>
                    <p className="mt-1 text-lg font-medium">
                      <span className="text-gray-900">{getPlanContentsById(plan.planId).name}</span>
                    </p>
                  </Link>
                </div>
              ))}
            </div>
          </>
        )}
      </main>

      <footer aria-labelledby="footer-heading" className="bg-gray-50">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-20">
            <div className="grid grid-cols-1 md:grid-cols-12 md:grid-flow-col md:gap-x-8 md:gap-y-16 md:auto-rows-min">
              {/* Image section */}
              <div className="col-span-1 md:col-span-2 lg:row-start-1 lg:col-start-1">
                <img
                  src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"
                  alt=""
                  className="h-8 w-auto"
                />
              </div>

              {/* Sitemap sections */}
              <div className="mt-10 col-span-6 grid grid-cols-2 gap-8 sm:grid-cols-3 md:mt-0 md:row-start-1 md:col-start-3 md:col-span-8 lg:col-start-2 lg:col-span-6">
                <div className="grid grid-cols-1 gap-y-12 sm:col-span-2 sm:grid-cols-2 sm:gap-x-8">
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">DEN</h3>
                    <ul className="mt-6 space-y-6">
                      {footerNavigation.company.map(item => (
                        <li key={item.name} className="text-sm">
                          <a href={item.href} className="text-gray-500 hover:text-gray-600">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-900">Customer Service</h3>
                  <ul className="mt-6 space-y-6">
                    {footerNavigation.customerService.map(item => (
                      <li key={item.name} className="text-sm">
                        <a href={item.href} className="text-gray-500 hover:text-gray-600">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Newsletter section */}
              <div className="mt-12 md:mt-0 md:row-start-2 md:col-start-3 md:col-span-8 lg:row-start-1 lg:col-start-9 lg:col-span-4">
                <h3 className="text-sm font-medium text-gray-900">Sign up for our newsletter</h3>
                <p className="mt-6 text-sm text-gray-500">The latest deals and savings, sent to your inbox weekly.</p>
                <form className="mt-2 flex sm:max-w-md">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    type="text"
                    autoComplete="email"
                    required
                    className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  />
                  <div className="ml-4 flex-shrink-0">
                    <button
                      type="submit"
                      className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-100 py-10 text-center">
            <p className="text-sm text-gray-500">&copy; 2021 Workflow, Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  )
}
