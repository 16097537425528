import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Grid } from 'react-loader-spinner'

const OLoader = ({ height = 60, width = 60, className }) => {
  return (
    <div className={classnames('flex flex-grow flex-1 mx-auto items-center justify-center', { [className]: true })}>
      <Grid color="#4340DE" height={height} width={width} />
    </div>
  )
}

OLoader.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
}

export default OLoader
