import create from 'zustand'
import { doc, getDoc, getFirestore, query, where, onSnapshot, documentId } from 'firebase/firestore'

import { COLLECTIONS, plansCollectionRef } from '../services/firebase'

import _ from 'lodash'

const upcoming = {
  engineer: {
    title: 'Everything you need before you start building',
    description:
      "We're working on building a step by step tutorial that will cover everything you need to do before you're ready to start building",
    imageSrc: '//cdn.shopify.com/s/files/1/0280/5429/0496/files/image1_1080x.jpg?v=1605802339',
    features: [
      {
        name: 'Permits',
        description: "Description of what we'll cover there",
      },
      {
        name: 'Placement',
        description: "Approx. 18' (top of foundation to roof peak)",
      },
      { name: 'Tools', description: 'Sleep 4 to 6' },
      {
        name: 'Materials',
        description: 'Lofted bedroom & Separate master bedroom',
      },
      {
        name: 'Breaking ground',
        description: 'Full bathroom with soaking tub',
      },
      {
        name: 'Water',
        description: 'Full kitchen with 4 burner stove and oven',
      },
      {
        name: 'Electricity',
        description: "Massive 6' x 6' picture windows",
      },
    ],
  },
  finance: {
    title: 'Best options with trusted partners',
    description:
      "We're working on building a step by step tutorial that will cover everything you need to do before you're ready to start building",
    imageSrc: '//cdn.shopify.com/s/files/1/0280/5429/0496/files/almahouse6_1080x.jpg?v=1627927804',
  },
  build: {
    title: 'DEN Masterclass',
    description:
      "We're working on building a step by step tutorial that will cover everything you need to do before you're ready to start building",
    imageSrc: '//cdn.shopify.com/s/files/1/0280/5429/0496/files/DSC02135_1296x.jpg?v=1628002005',
  },
  live: {
    title: 'Ready made shoping list',
    description: "We're working on building a space with hand-picked furniture and appliance for your new DEN",
    imageSrc: '//cdn.shopify.com/s/files/1/0280/5429/0496/files/BIGGER_BARNHOUSE_-_07.3_1080x.jpg?v=1610423893',
  },
  rent: {
    title: 'Ready to let others enjoy your DEN?',
    description: "This will be a place where we'll help you to prepare for renting your DEN",
    imageSrc: '//cdn.shopify.com/s/files/1/0280/5429/0496/files/image5_1080x.jpg?v=1605802408',
  },
}

const plansContent = [
  {
    id: 'modern-loft-barnhouse',
    date: 'July 12, 2021',
    datetime: '2021-07-12',
    package: 'Starter Pkg.',
    name: 'Modern Loft Barnhouse',
    href: '#',
    imageSrc: '//cdn.shopify.com/s/files/1/0280/5429/0496/products/MODERN_BARNHOUSE_05_1800x1800.jpg?v=1636405271',
    imageAlt: 'Modern Loft Barnhouse',
    version: { name: '1.0', date: 'June 5, 2021', datetime: '2021-06-05' },
    description: `This is the modern-day nature seeker’s Scandinavian style farmhouse. Beautiful minimalism with an emphasis on function offers a comfortable and stylish outpost for hosting and small families.`,
    highlights: ["880 sq ft. (40' Length x 16' Width plus loft)", 'Sleep 4 to 6', '$196,680 Cost to Build'],
    packages: [
      {
        name: 'Dreamers package',
        description: "This is what you've purchased",
      },
      {
        name: 'Builders package',
        description: 'Full access to everything regarding this plan',
      },
    ],
    images: [
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/MODERN_BARNHOUSE_05_1800x1800.jpg?v=1640200853',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/MODERN_BARNHOUSE_17_1800x1800.jpg?v=1640200855',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/MODERN_BARNHOUSE_08_1800x1800.jpg?v=1640200858',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/MODERN_BARNHOUSE_06_1800x1800.jpg?v=1640200861',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/MODERN_BARNHOUSE_21_1800x1800.jpg?v=1640200863',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/MODERN_BARNHOUSE_23_1800x1800.jpg?v=1640200865',
    ],
    features: {
      title: 'Features',
      description: `Let the natural world pass through your Den with massive picture windows and sliding glass doors perfectly aligned for a feeling of being one with nature inside of your own safe space. Walkout wrap around decking, two full bedrooms, and lofted ceilings add to the staples of a home that this full-featured farmhouse provides.`,
      items: [
        {
          title: 'Dimensions',
          body: "880 sq ft. (40' Length x 16' Width plus loft)",
        },
        {
          title: 'Height',
          body: `Approx. 18' (top of foundation to roof peak)`,
        },
        { title: 'People', body: `Sleep 4 to 6` },
        { title: 'Bedrooms', body: `Lofted bedroom & Separate master bedroom` },
        { title: 'Bathroom', body: `Full bathroom with soaking tub` },
        { title: 'Kitchen', body: `Full kitchen with 4 burner stove and oven` },
        { title: 'Windows', body: `Massive 6' x 6' picture windows` },
        { title: 'Loundry', body: `Laundry closet ample storage` },
        { title: 'Heating', body: `Wood or gas stove` },
        { title: 'A/C', body: `Mini-split for heat & A/C` },
        {
          title: 'Cost to build',
          body: `$196,680 (based on the national average of $223.50/sq ft.)`,
        },
      ],
    },
    customization: {
      title: 'Need customization?',
      description: `This is the modern-day nature seeker's Scandinavian style farmhouse. Beautiful minimalism with an emphasis on function offers a comfortable and stylish outpost for hosting and small families.`,
      headerImage: '//cdn.shopify.com/s/files/1/0280/5429/0496/products/MODERN_BARNHOUSE_21_1800x1800.jpg?v=1640200863',
    },
  },
  {
    id: 'modern-alpine-plus',
    date: 'July 14, 2021',
    datetime: '2021-07-14',
    package: 'Complete Pkg.',
    name: 'Modern Alpine Plus',
    href: '#',
    imageSrc: '//cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_08_1800x1800.jpg?v=1633454561',
    imageAlt: 'Modern Alpine Plus',
    version: { name: '1.0', date: 'June 5, 2021', datetime: '2021-06-05' },
    description: `This is the modern-day nature seeker's Scandinavian style farmhouse. Beautiful minimalism with an emphasis on function offers a comfortable and stylish outpost for hosting and small families.`,
    highlights: ["880 sq ft. (40' Length x 16' Width plus loft)", 'Sleep 4 to 6', '$196,680 Cost to Build'],
    packages: [
      {
        name: 'Dreamers package',
        description: "This is what you've purchased",
      },
      {
        name: 'Builders package',
        description: 'Full access to everything regarding this plan',
      },
    ],
    images: [
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_08_1800x1800.jpg?v=1640200853',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_13_1800x1800.jpg?v=1640200855',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_17_1800x1800.jpg?v=1640200858',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_18_1800x1800.jpg?v=1640200861',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_02_1800x1800.jpg?v=1640200864',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_19_1800x1800.jpg?v=1640200866',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_21_1800x1800.jpg?v=1640200868',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_04_1800x1800.jpg?v=1640200871',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_06_1800x1800.jpg?v=1640200874',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_09_1800x1800.jpg?v=1640200877',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_12_1800x1800.jpg?v=1640200880',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_14_1800x1800.jpg?v=1640200882',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_15_1800x1800.jpg?v=1640200885',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_16_1800x1800.jpg?v=1640200887',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_20_1800x1800.jpg?v=1640200890',
      'https://cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_05_1800x1800.jpg?v=1640200892',
    ],
    features: {
      title: 'Features',
      description: `Let the natural world pass through your Den with massive picture windows and sliding glass doors perfectly aligned for a feeling of being one with nature inside of your own safe space. Walkout wrap around decking, two full bedrooms, and lofted ceilings add to the staples of a home that this full-featured farmhouse provides.`,
      items: [
        {
          title: 'Dimensions',
          body: "880 sq ft. (40' Length x 16' Width plus loft)",
        },
        {
          title: 'Height',
          body: `Approx. 18' (top of foundation to roof peak)`,
        },
        { title: 'People', body: `Sleep 4 to 6` },
        { title: 'Bedrooms', body: `Lofted bedroom & Separate master bedroom` },
        { title: 'Bathroom', body: `Full bathroom with soaking tub` },
        { title: 'Kitchen', body: `Full kitchen with 4 burner stove and oven` },
        { title: 'Windows', body: `Massive 6' x 6' picture windows` },
        { title: 'Loundry', body: `Laundry closet ample storage` },
        { title: 'Heating', body: `Wood or gas stove` },
        { title: 'A/C', body: `Mini-split for heat & A/C` },
        {
          title: 'Cost to build',
          body: `$196,680 (based on the national average of $223.50/sq ft.)`,
        },
      ],
    },
    customization: {
      title: 'Need customization?',
      description: `This is the modern-day nature seeker's Scandinavian style farmhouse. Beautiful minimalism with an emphasis on function offers a comfortable and stylish outpost for hosting and small families.`,
      headerImage: '//cdn.shopify.com/s/files/1/0280/5429/0496/products/ALPINE_COTTAGE_17_1800x1800.jpg?v=1640200858',
    },
  },
]

const useStore = create((set, get) => ({
  user: null,
  isDataLoaded: false,
  plans: [],
  plansContents: plansContent,
  upcoming: upcoming,
  plansAccess: {},
  plansQueryListener: () => {
    console.log('Unsibscribe on PLANS listerner called but not set yet')
  },

  /**
   * Load all PLANS and listen for changes
   */
  loadPlans: (planIds, plansAccess) => {
    const plansQuery = query(plansCollectionRef, where(documentId(), 'in', planIds))
    const unsubscribe = onSnapshot(plansQuery, querySnapshot => {
      const plans = []
      querySnapshot.forEach(doc => {
        plans.push({ ...doc.data(), id: doc.id, access: plansAccess[doc.id] })
      })
      set({ plans })
    })
    set({ plansQueryListener: unsubscribe })
  },

  getPlanById: planId => {
    return _.find(get().plans, { id: planId })
  },
  getPlanContentsById: planContentId => {
    return _.find(get().plansContents, { id: planContentId })
  },

  getUserOwnedPlans: () => {
    return _.filter(get().plans, function (plan) {
      return plan.access === 'owner'
    })
  },

  getSharedWithUserPlans: () => {
    return _.filter(get().plans, function (plan) {
      return plan.access !== 'owner'
    })
  },

  /**
   * INITIAL LOAD
   * @param {*} userId
   *
   */
  loadUserData: async (userId, authUser) => {
    if (!userId) {
      return
    }

    const tokenResult = await authUser.getIdTokenResult(true)
    const plansAccess = tokenResult.claims.plans
    const db = getFirestore()
    const userRef = doc(db, COLLECTIONS.users, userId)

    const userSnap = await getDoc(userRef)
    console.log({ plansAccess })
    if (userSnap.exists()) {
      const userData = userSnap.data()

      set({
        user: { ...userData, id: userSnap.id },
        isAuthDataLoaded: true,
        plansAccess: plansAccess,
      })
      const planIds = Object.keys(plansAccess)
      if (planIds.length) {
        get().loadPlans(planIds, plansAccess)
      }
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!')
    }
  },

  /**
   * CLEANUP
   */
  unsubscribeListeners: () => {
    console.log('Store unsunbscribing from listeners')
    get().plansQueryListener()
  },
}))

export default useStore
