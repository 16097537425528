const CONFIG = {
  firebaseConfig: {
    apiKey: 'AIzaSyDOPZ0CQJqgFPLrIBtZkAQ5_iUtiIiU2Hs',
    authDomain: 'denoutdoors-36c0c.firebaseapp.com',
    projectId: 'denoutdoors-36c0c',
    storageBucket: 'denoutdoors-36c0c.appspot.com',
    messagingSenderId: '162534617544',
    appId: '1:162534617544:web:60ee32d18ed9359653d0fd',
  },
  pdfLicenseKey:
    'o5T9zaWAy-SjiqcGvQrtxXTIbLK8JCyxcnT6yWVMLiURxV41KgcfI1whTPFyaLOy9I2LuREl6pKnKVDN4UhBCArVPSgReaWpwnELimPhPkfGEjZmYBq1TXGbc5jlgZT3e4N9F9OuooUTep6fZJ3E0G4tIi-j4mEJdcbgjllDav1k1DSeveRfdIEJNS7pVv0vvnRuOjPwd5_F9yYnSwuXHuTys0AI99LxJ1YlbP440PfeLgTjSkQVH5CLqw8ISEZmx3wWzzQWma2A-jg4Pyw7hfLfuGClSwnrrVLQAHXmH7FMOMQGYgayF2O3wA_pR2usZhHTwa45k_Fq5xAdQSGP4DIIUggU-lDaCYFc4CEctq-8zX-txs_fkj-GL3Dl0JymT5F7wECLPlyVmfr7CrdVqgK1c66CdqN-UOVdJZ5UXGr6Q7K366Fhvuxws6UwEzHybeJ-6msucx6mgyVKLuJLy6oMszDYU5zjGYR_U0XexPSzUbqANNNHzFf4g2TxFoPXwMikHbKIQRFiR0F2dCkFDc_R_vAhx4XNe-nAxHbX2JpQl_XtmWSvQ-vHlHcepuGqIV9CfWnIiAOonzaLBssPheQUBnMtFfp6Qu7ptwSEUqhv8mBgWN9tJAlzpIXLPtWo',
  domain: 'https://dev.dashboard.denoutdoors.com',
}

export default CONFIG

//production
//domain: 'https://dev.dashboard.denoutdoors.com'

//dev
//domain: 'http://localhost:3000'
