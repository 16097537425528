import { getAuth } from 'firebase/auth'
import { getFirestore, collection, documentId } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import firebase from 'firebase/compat/app'
// import { initializeApp } from "firebase/app"

import 'firebase/compat/auth'
import CONFIG from '../config/config'
let firebaseApp
if (!firebaseApp) {
  firebaseApp = firebase.initializeApp(CONFIG.firebaseConfig)
}

export const COLLECTIONS = {
  users: 'users',
  plans: 'plans',
}

export const firestoreDb = getFirestore(firebaseApp)
export const functions = getFunctions(firebaseApp)
export const doesUserExistFBFunction = httpsCallable(functions, 'doesUserExist')
export const getPlanAccess = httpsCallable(functions, 'getPlanAccess')
export const inviteToPlan = httpsCallable(functions, 'inviteToPlan')

export const usersCollectionRef = collection(firestoreDb, COLLECTIONS.users)

export const plansCollectionRef = collection(firestoreDb, COLLECTIONS.plans)

export const auth = getAuth(firebaseApp)

export { firebaseApp, firebase, documentId }
