import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import { RadioGroup } from '@headlessui/react'
import { ShieldCheckIcon } from '@heroicons/react/outline'

import _ from 'lodash'

import useStore from '../store/useStore'

/* This example requires Tailwind CSS v2.0+ */
// const features = [
//   {
//     name: "Dimensions",
//     description: " 880 sq ft. (40' Length x 16' Width plus loft)",
//   },
//   {
//     name: "Height",
//     description: "Approx. 18' (top of foundation to roof peak)",
//   },
//   { name: "People", description: "Sleep 4 to 6" },
//   { name: "Bedrooms", description: "Lofted bedroom & Separate master bedroom" },
//   { name: "Bathroom", description: "Full bathroom with soaking tub" },
//   {
//     name: "Kitchen",
//     description: "Full kitchen with 4 burner stove and oven",
//   },
//   {
//     name: "Windows",
//     description: "Massive 6' x 6' picture windows",
//   },
//   {
//     name: "Loundry",
//     description: "Laundry closet ample storage",
//   },
//   {
//     name: "Heating",
//     description: "Wood or gas stove",
//   },
//   {
//     name: "A/C",
//     description: "Mini-split for heat & A/C",
//   },
//   {
//     name: "Cost to build",
//     description: "$196,680 (based on the national average of $223.50/sq ft.)",
//   },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Features({ title = 'Features', description = '', features = [], images = [] }) {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
        <div>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">{title}</h2>
          <p className="mt-4 text-gray-500">{description}</p>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map(feature => (
              <div key={feature.title} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.title}</dt>
                <dd className="mt-2 text-sm text-gray-500">{feature.body}</dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          {images.map((image, index) => (
            <img key={index} src={image} alt="" className="bg-gray-100 rounded-lg" />
          ))}
        </div>
      </div>
    </div>
  )
}

export default function ProjectOverview() {
  const { projectId } = useParams()
  const plans = useStore(state => state.plans)
  const plansContents = useStore(state => state.plansContents)
  const getPlanById = useStore(state => state.getPlanById)
  const getPlanContentsById = useStore(state => state.getPlanContentsById)
  const [selectedPackage, setSelectedPackage] = useState(null)

  const plan = useMemo(() => {
    if (projectId && plans.length > 0 && plansContents.length > 0) {
      const planObj = getPlanById(projectId)
      return getPlanContentsById(planObj.planId)
    }
    return null
  }, [projectId, plans.length, plansContents.length, getPlanById, getPlanContentsById])

  useEffect(() => {
    if (plan) {
      setSelectedPackage(plan?.packages[0])
    }
  }, [plan])

  if (!plan) {
    return null
  }

  return (
    <>
      {/* plan */}
      <div className="lg:grid lg:grid-rows-1 lg:grid-cols-7 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
        {/* plan image */}
        <div className="lg:row-end-1 lg:col-span-4">
          <div className="aspect-w-4 aspect-h-3 rounded-lg bg-gray-100 overflow-hidden">
            <img src={plan.imageSrc} alt={plan.imageAlt} className="object-center object-cover" />
          </div>
        </div>

        {/* plan details */}
        <div className="max-w-2xl mx-auto mt-14 sm:mt-16 lg:max-w-none lg:mt-0 lg:row-end-2 lg:row-span-2 lg:col-span-3">
          <div className="flex flex-col-reverse">
            <div className="mt-4">
              <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">{plan.name}</h1>

              <h2 id="information-heading" className="sr-only">
                plan information
              </h2>
              <p className="text-sm text-gray-500 mt-2">
                Version {plan.version.name} (Updated <time dateTime={plan.version.datetime}>{plan.version.date}</time>)
              </p>
            </div>
          </div>

          <p className="text-gray-500 mt-6">{plan.description}</p>
          {/* plan form */}
          <div className="mt-10 lg:max-w-lg lg:col-start-1 lg:row-start-2 lg:self-start">
            <section aria-labelledby="options-heading">
              <h2 id="options-heading" className="sr-only">
                Package options
              </h2>
              <div className="mt-6 text-center">
                <a href="#" className="group inline-flex text-base font-medium">
                  <ShieldCheckIcon
                    className="flex-shrink-0 mr-2 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="text-gray-500 hover:text-gray-700">Lifetime Updates</span>
                </a>
              </div>
              <form>
                <div className="sm:flex sm:justify-between">
                  {/* package selector */}
                  <RadioGroup value={selectedPackage} onChange={setSelectedPackage}>
                    <RadioGroup.Label className="block text-sm font-medium text-gray-700">Package</RadioGroup.Label>
                    <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                      {plan.packages.map(pkg => (
                        <RadioGroup.Option
                          as="div"
                          key={pkg.name}
                          value={pkg}
                          className={({ active }) =>
                            classNames(
                              active ? 'ring-2 ring-indigo-500' : '',
                              'relative block border border-gray-300 rounded-lg p-4 cursor-pointer focus:outline-none'
                            )
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <RadioGroup.Label as="p" className="text-base font-medium text-gray-900">
                                {pkg.name}
                              </RadioGroup.Label>
                              <RadioGroup.Description as="p" className="mt-1 text-sm text-gray-500">
                                {pkg.description}
                              </RadioGroup.Description>
                              <div
                                className={classNames(
                                  active ? 'border' : 'border-2',
                                  checked ? 'border-indigo-500' : 'border-transparent',
                                  'absolute -inset-px rounded-lg pointer-events-none'
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
                <div className="mt-4">
                  <a href="#" className="group inline-flex text-sm text-gray-500 hover:text-gray-700">
                    <span>What are the differences?</span>
                    <QuestionMarkCircleIcon
                      className="flex-shrink-0 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </a>
                </div>
                {selectedPackage === plan.packages[1] && (
                  <div className="mt-10">
                    <button
                      type="submit"
                      className="w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                    >
                      Upgrade for $999
                    </button>
                  </div>
                )}
              </form>
            </section>
          </div>

          {selectedPackage === plan.packages[0] && (
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
              <button
                type="button"
                className="w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
              >
                Download Your Plan
              </button>
              <button
                type="button"
                className="w-full bg-indigo-50 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-indigo-700 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
              >
                Get Receipt
              </button>
            </div>
          )}
        </div>
      </div>
      <Features
        title={plan?.features?.title}
        description={plan?.features?.description}
        features={plan?.features?.items}
        images={_.shuffle(plan.images).slice(0, 6) || []}
      />
    </>
  )
}
